import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerSetup from '../components/BannerSetup'

import mixxxLogo from '../assets/images/mixxx-logo.png'
import mixxx from '../assets/images/mixxx.png'

const Setup = props => (
  <Layout>
    <Helmet>
      <title>Mix Rider Setup</title>
      <meta name="description" content="Get up and running with Mix Rider" />
    </Helmet>

    <BannerSetup />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Overview</h2>
          </header>
          <p>
            To use Mix Rider you need to install the app on your device, connect
            your device to you computer, and setup controller mappings with your
            DJ software
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link to="/generic" className="image">
            <img src={mixxx} alt="MIXXX Logo" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>
                  Set up with <img src={mixxxLogo} alt="MIXXX Logo" />
                </h3>
              </header>
              <ol>
                <li>
                  <p>
                    Install Mixxx on your computer, if you don't have it already,
                    from <a href="https://www.mixxx.org/download/">here.</a>
                  </p>
                </li>
                <li>
                  <p>
                    Download this <a href="https://mixrider.com/downloads/mixxx-rider-scripts.js">mapping file</a> and{' '}
                    <a href="https://mixrider.com/downloads/mixxx-rider.midi.xml">script file</a> and place in the User
                    Controller Mapping Folder on your computer:
                  </p>
                  <ul>
                    <li>{`GNU/Linux: /home/<username>/.mixxx/controllers`}</li>
                    <li>{`OS X: /Users/<username>/Library/Application Support/Mixxx/controllers`}</li>
                    <li>{`Windows: C:\\Users\\<username>\\AppData\\local\\Mixxx\\controllers`}</li>
                  </ul>
                  <p>
                    See{' '}
                    <a href="https://github.com/mixxxdj/mixxx/wiki/Controller%20mapping%20file%20locations">
                      here
                    </a>{' '}
                    for further details about the user mapping folder in Mixxx.
                  </p>
                </li>

                <li>
                  <p>Install Mix Rider on your Android Device.</p>
                  <div className="play-store-button">
                    <a href="https://play.google.com/store/apps/details?id=com.spinims.rider&hl=en-GB&ah=2QFlzfMPPrRnisWWVXPNz_kGnhk&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                      <img
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <p>Connect your device to your computer via a USB cable.</p>
                </li>
                <li>
                  <p>Set the USB connection in Android to MIDI.</p>
                  <p>
                    Pro tip: set this behaviour to the default using{' '}
                    <a href="https://www.techrepublic.com/article/how-to-set-the-default-usb-behavior-in-android-10/">
                      this method.
                    </a>
                  </p>
                </li>
                <li>Start Mix Rider on your device.</li>
                <li>Then start Mixxx.  In preferences, enable your device as a controller, then load the 'Mix Rider for Android' preset.</li>
              </ol>
              <ul className="actions">
                <li>
                  <Link to="/howtouse" className="button">
                    How to use the app
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Setup
